<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
     <CircularProgress v-if="loader"/>
     <v-card color="#373535" elevation="0">

       <v-row no-gutters>
         <v-col class="mt-7 pl-7" cols="9">
             <div class="mt-2">
             <span class="pageTitle">Add a New Customer</span>
             </div> 
         </v-col> 
         <v-col class="mt-8 pr-4 text-right" cols="3">
          <v-btn @click="toListCustomer()" fab dark class="" color="#434242" elevation="0" small>
              <v-icon size="15" color="#ffffff" dark>$xMarkIcon</v-icon>
          </v-btn>
         </v-col>  
       </v-row>

      <v-row no-gutters>
         <v-col>
           <v-form ref="form">
          <div class="mt-8 ml-5 mr-5">
            <v-select :rules="communityRules" v-model="customerInfo.customerCommunityInfo.customerCommunityId" outlined :items="communities" full-width single-line label="Community" background-color="#f4f8f7" color="#4B9C6D" autocomplete="off" prepend-inner-icon="mdi-map-marker"></v-select>
            <v-text-field :rules="houseNoRules" v-model="customerInfo.customerCommunityInfo.customerHouseNo" outlined full-width single-line label="House No." background-color="#f4f8f7" color="#4B9C6D" autocomplete="off" prepend-inner-icon="mdi-home"></v-text-field>
            <v-text-field :rules="customerNameRules" v-model="customerInfo.customerBasicInfo.customerName" outlined full-width single-line label="Customer Name" background-color="#f4f8f7" color="#4B9C6D" autocomplete="off" prepend-inner-icon="mdi-account"></v-text-field>
            <v-text-field type="number" :rules="customerMobileRules" v-model="customerInfo.customerBasicInfo.customerMobile" outlined full-width single-line label="Mobile No." background-color="#f4f8f7" color="#4B9C6D" autocomplete="off" prepend-inner-icon="mdi-phone"></v-text-field>
            <v-radio-group :rules="customerGenderRules" v-model="customerInfo.customerBasicInfo.customerGender" class="ma-0 mb-2 pa-0" row >
                <v-radio label="Female" dark color="#4B9C6D" value="Female"></v-radio>
                <v-radio label="Male" dark color="#4B9C6D" value="Male"></v-radio>
            </v-radio-group>
            
            <!-- <v-text-field disabled v-model="communityName" outlined full-width single-line label="House No." background-color="#f4f8f7" color="#4B9C6D" autocomplete="off" prepend-inner-icon="mdi-map-marker"></v-text-field> -->
            
          </div>
          </v-form>
         </v-col> 
      </v-row>
      <v-row no-gutters>
       <v-col>
         <div class="mr-4 ml-4">
         <v-btn @click="createNewCustomer(customerInfo)" class="saveCustomer" height="50px" large block text dark elevation="0">
           Save Customer
         </v-btn>
         </div>
       </v-col>  
     </v-row>
     <v-snackbar :timeout="5000" :value="true" v-if="errorMessage !=''"  absolute bottom color="#E14B4B" text>
      {{errorMessage}}
    </v-snackbar>
     </v-card>

    </v-dialog>
</template>
<script>
import CircularProgress from '../../loaders/CircularProgress.vue'
import customerServices from '@/services/customerServices'
import router from '@/router'
  export default {
    components: {
        CircularProgress
    },
    data () {
      return {
        communities: [],
        loader: false,
        dialog: true,
        errorMessage: "",
        communityName: "",
        customerInfo: {
          partnerId: '',
          customerBasicInfo: {
            customerName: "",
            customerMobile: "",
            customerGender: "Female"
          },
          customerCommunityInfo: {
            customerCommunityId: "",
            customerHouseNo: ""
          },
          status:1
        },
    customerNameRules: 
          [
              v => !!v || 'Customer Name is required',
              v => (v && v.length <= 24) || 'Customer Name must be less than 12 characters',
              v => (v && v.length >= 3) || 'Customer Name must be atleast 03 characters'
          ],
          houseNoRules: 
          [
              v => !!v || 'House No is required',
              v => (v && v.length <= 300) || 'House No must be less than 300 characters',
              v => (v && v.length >= 1) || 'House No must be atleast 01 characters'
          ],
      customerMobileRules:
          [
              v => !!v || 'Mobile No is required',
              v => (v && v.length === 10) || 'Mobile No must be 10 characters'
          ],
        customerGenderRules: [v => !!v || 'Gender is required'],
        communityRules: [v => !!v || 'Community is required'],
     
      }
    },
    methods: {
      async createNewCustomer (customerInfo) {
        if (this.$refs.form.validate()) {
          this.customerInfo.partnerId = this.$store.state.partnerInfo.partnerId
          this.loader = true
        try {
          await customerServices.createCustomer(customerInfo).then(result => {
              if (result.data.status == "success") {
                this.loader = false
                router.push('customers')
              } else {
                this.loader = false
                this.errorMessage = result.data.message
              }
            }
          )
        } catch (err) {
          console.log(err)
        }
        }
      },
      toListCustomer () {
        this.dialog = false,
        router.push('customers')
      }
    },
    async mounted () {
      const partnerCommunitiesList = (await customerServices.viewPartnerCommunities(this.$store.state.partnerInfo.partnerId)).data;
      for (var x = 0; x < partnerCommunitiesList.partnerCommunities.length; x++) {
        this.communities.push({
          text: partnerCommunitiesList.partnerCommunities[x].communityId.communityName,
          value: partnerCommunitiesList.partnerCommunities[x].communityId._id
        })
      }
    }
  }
</script>
<style scoped>
.pageTitle {
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 18px;
color: #FFFFFF;
}
.saveCustomer {
text-transform: unset !important;
background: #4B9C6D;
font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 29px;
color: #FFFFFF;
opacity: 0.8;
}

</style>